// import 'core-js/stable';
// import 'regenerator-runtime/runtime';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { Lazyload } from 'vant';
import 'amfe-flexible';
import '@vant/touch-emulator';
// import VConsole from 'vconsole';
import '@/utils/permissions.js';


// if (process.env.NODE_ENV === 'production') {
//   new VConsole();
// }

// function is_weixin() {
//   var ua = navigator.userAgent.toLowerCase(); //判断浏览器的类型
//   if (ua.match(/MicroMessenger/i) == "micromessenger") {
//     return true;
//   } else {
//     return false;
//   }
// }

// if (!is_weixin()) { // 如果不是微信内置浏览器，就动态跳转到以下页面
//   window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf5df52103ef4948d&redirect_uri=http%3a%2f%2fh5.codecattle.com%2f%23%2flogin?redirect=/home&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1&uin=MjU0NjY4MDYwNA%3D%3D&key=fe1a5aa8d5ddb85f085b0dd7d45bf2ef19658219dae9b1c8758be03eb643adf42cc7c000550ff732bb5e900f29576878&pass_ticket=RH8M93qgnswtIMmxdjiLcXOhp17VeW9sgMV4SDGt0jFHA8sIDenPCPDTN7vo0lm1Db4Q1LlX5bCPa5IFDp29BA==';
// }


// router.afterEach((to, from) => {
//   console.log(to,from)
//   let path = to.fullPath.slice(1) // 去除'/'
//   if (!sessionStorage.getItem('initLink')) {
//     // 解决ios微信下，分享签名不成功的问题,将第一次的进入的url缓存起来。
//     sessionStorage.setItem('initLink', document.URL)
//   }
//   let url
//   if (!!window.__wxjs_is_wkwebview) {
//     // ios
//     url = sessionStorage.getItem('initLink')
//   } else {
//     // 安卓 process.env.BASE_URL 自己定义各个环境下域名变量
//     url = location.origin + process.env.BASE_URL + path
//   }
//   store.commit('page/setInitLink', url)
// })


createApp(App).use(store).use(router).use(Lazyload, {
  lazyComponent: true,
})
  .mount('#app');
