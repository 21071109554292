/**
 * @description 路由守卫
 */
import {
  routesWhiteList,
  bindphoneRoutesList
} from '@/config';
import router from '@/router';
import store from '@/store';
import { toLoginRoute } from '@/utils/routes';

router.beforeEach(async (to, from, next) => {
  const token = store.getters['user/token'];
  const { phone, isDoctor } = store.getters['user/userInfo'];
  if (token) {
    if (to.meta.isDoctor == 0 && isDoctor) {
      next('health');
    } else if (to.meta.isDoctor && to.meta.isDoctor == 1 && !isDoctor) {
      next('home');
    }
    if (!isDoctor && (bindphoneRoutesList.includes(to.path) || bindphoneRoutesList.includes(to.name)) && !phone) {
      next({
        name: 'bindPhone',
        params: { to: from.fullPath },
      });
    }
    next();
  } else if (routesWhiteList.includes(to.path)) {
    next();
  } else next(toLoginRoute(to.path));
});
router.afterEach((to) => {
  document.title = to.meta.title ? to.meta.title : '比心健康';
  window.scrollTo(0, 0);
  // chrome
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  // safari
  window.pageYOffset = 0;
});
