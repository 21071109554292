<template>
  <transition name="fade">
    <div
      class="button-container"
      :style="{ bottom: hasHome ? '65px' : '65px' }"
      v-show="isShow"
    >
      <van-button
        round
        type="default"
        v-show="hasHome"
        :to="isDoctor ? '/health' : '/home'"
        replace
      >
        <van-icon name="home-o" size="22" color="#BCC5CB" />
      </van-button>

      <van-button round type="default" @click="handleToTop">
        <van-icon name="back-top" size="10" color="#BCC5CB" />
        <div>Top</div>
      </van-button>
    </div>
  </transition>
</template>

<script>
import { Button, Icon } from 'vant';
import { useEventListener } from '@vant/use';
import { useRoute } from 'vue-router';
import { ref, watch } from 'vue';
import store from '@/store';

export default {
  components: {
    [Button.name]: Button,
    [Icon.name]: Icon,
  },
  setup() {
    const isShow = ref(false);
    const { isDoctor } = store.getters['user/userInfo'];

    // 监听滚动轴
    useEventListener('scroll', () => {
      if (document.documentElement.scrollTop > 100) {
        isShow.value = true;
      } else {
        isShow.value = false;
      }
    });

    const route = useRoute();
    const hasHome = ref(false);
    initHasHome(route.name);
    function initHasHome(name) {
      if (
        name == 'ask' ||
        name == 'my' ||
        name == 'home' ||
        name == 'health' ||
        name == 'platform'
      ) {
        hasHome.value = false;
      } else {
        hasHome.value = true;
      }
    }

    // 监听路由
    watch(
      () => route.name,
      (name) => {
        initHasHome(name);
      },
    );

    const handleToTop = function () {
      document.documentElement.scrollIntoView({ behavior: 'smooth' });
    };

    return {
      hasHome,
      isShow,
      handleToTop,
      bottom: '65px',
      isDoctor,
    };
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.8s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.button-container {
  position: fixed;
  padding-bottom: constant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
  padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS>11.2*/
  right: 0;
  button {
    width: 40px;
    height: 40px;
    font-size: 9px;
    color: #bcc5cb;
    font-weight: 500;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.06);
    // display: flex;
    // align-items: center;
    // justify-content: center;
    margin-right: 15px;
  }
}
</style>
