<template>
  <div style="position: fixed; width: 100%; bottom: 0; left: 0">
    <div v-if="isDoctor" style="max-width: 575px; margin: 0 auto; position: relative">
      <van-tabbar v-model="active" fixed style="position: absolute">
        <van-tabbar-item name="health" to="health">
          <template #icon="props">
            <div class="tabbar-item toflex todirection">
              <van-image
                style="margin: 0 auto"
                width="18px"
                height="18px"
                :src="props.active ? require('@/assets/tabbar-home-act2.png') : require('@/assets/tabbar-wz.png')"
              ></van-image>
              <div :class="props.active ? 'active' : ''">健康服务</div>
            </div>
          </template>
        </van-tabbar-item>
        <van-tabbar-item name="platform" to="platform">
          <template #icon="props">
            <div class="tabbar-item toflex todirection">
              <van-image
                style="margin: 0 auto"
                width="18px"
                height="18px"
                :src="props.active ? require('@/assets/tabbar-sys-act.png') : require('@/assets/tabbar-sys.png')"
              ></van-image>
              <div :class="props.active ? 'active' : ''">使用教程</div>
            </div>
          </template>
        </van-tabbar-item>
        <van-tabbar-item name="my" to="my">
          <template #icon="props">
            <div class="tabbar-item toflex todirection">
              <van-image
                style="margin: 0 auto"
                width="18px"
                height="18px"
                :src="props.active ? require('@/assets/tabbar-my-act2.png') : require('@/assets/tabbar-my.png')"
              ></van-image>
              <div :class="props.active ? 'active' : ''">我的</div>
            </div>
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>

    <div v-else style="max-width: 575px; margin: 0 auto; position: relative">
      <van-tabbar v-model="active" fixed style="position: absolute">
        <van-tabbar-item name="home" to="home">
          <template #icon="props">
            <div class="tabbar-item linear-blue toflex" v-if="props.active">
              <img :src="require('@/assets/tabbar-home-act.png')" srcset="" />
              <span>咨询</span>
            </div>
            <img class="inactive-img" v-else :src="require('@/assets/tabbar-home.png')" srcset="" />
          </template>
        </van-tabbar-item>
        <van-tabbar-item name="ask" to="ask">
          <template #icon="props">
            <div class="tabbar-item linear-blue toflex" v-if="props.active">
              <img :src="require('@/assets/tabbar-wz-act.png')" srcset="" />
              <span>问诊</span>
            </div>
            <img class="inactive-img" v-else :src="require('@/assets/tabbar-wz.png')" srcset="" />
          </template>
        </van-tabbar-item>
        <van-tabbar-item name="my" to="my">
          <template #icon="props">
            <div class="tabbar-item linear-blue toflex" v-if="props.active">
              <img :src="require('@/assets/tabbar-my-act.png')" srcset="" />
              <span>我的</span>
            </div>
            <img class="inactive-img" v-else :src="require('@/assets/tabbar-my.png')" srcset="" />
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { Tabbar, TabbarItem, Image } from 'vant';
import { useRoute } from 'vue-router';
import store from '@/store';

export default {
  name: 'Index',
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [Image.name]: Image,
  },
  setup() {
    const { isDoctor } = store.getters['user/userInfo'];

    // 获取当前页path
    const route = useRoute();
    const active = ref(route.name);

    // 监听路由变化，更新路由
    watch(
      () => route.name,
      async (newParams) => {
        active.value = newParams;
      },
    );
    return {
      active,
      isDoctor,
    };
  },
};
</script>

<style lang="scss" scoped>
.tabbar-item {
  width: 85px; // ignore
  height: 35px; // ignore
  border-radius: 35px;
  text-align: center;
}
.toflex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.todirection {
  flex-direction: column;
}
.tabbar-item span {
  padding-left: 5px;
  font-size: $font15;
  color: #ffffff;
  font-weight: 400;
}
.tabbar-item div {
  font-size: $font11;
  color: #a6aeb3;
  font-weight: 400;
  margin-top: 4px;
}
.tabbar-item .active {
  color: #5e7dff;
}
.tabbar-item img {
  width: 18px;
  height: 18px;
}
</style>
