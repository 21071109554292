import cookie from 'js-cookie';
import { storage } from '@/config';
/**
 * @description 获取
 * @returns {string|ActiveX.IXMLDOMNode|Promise<any>|any|IDBRequest<any>|MediaKeyStatus|FormDataEntryValue|Function|Promise<Credential | null>}
 */
export function getStorage(key) {
  if (storage) {
    if (storage === 'localStorage') {
      return localStorage.getItem(key);
    } else if (storage === 'sessionStorage') {
      return sessionStorage.getItem(key);
    } else if (storage === 'cookie') {
      return cookie.get(key);
    } else {
      return localStorage.getItem(key);
    }
  } else {
    return localStorage.getItem(key);
  }
}

/**
 * @description 存储
 * @param data
 * @returns {void|*}
 */
export function setStorage(data, key) {
  if (localStorage) {
    if (storage === 'localStorage') {
      return localStorage.setItem(key, data);
    } else if (storage === 'sessionStorage') {
      return sessionStorage.setItem(key, data);
    } else if (storage === 'cookie') {
      return cookie.set(key, data);
    } else {
      return localStorage.setItem(key, data);
    }
  } else {
    return localStorage.setItem(key, data);
  }
}

/**
 * @description 移除
 * @returns {void|Promise<void>}
 */
export function removeStorage(key) {
  if (localStorage) {
    if (storage === 'localStorage') {
      return localStorage.removeItem(key);
    } else if (storage === 'sessionStorage') {
      return sessionStorage.clear();
    } else if (storage === 'cookie') {
      return cookie.remove(key);
    } else {
      return localStorage.removeItem(key);
    }
  } else {
    return localStorage.removeItem(key);
  }
}
