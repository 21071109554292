import { createRouter, createWebHashHistory } from 'vue-router';
import FastBottomButton from '@/components/FastBottomButton.vue';
import Tabbar from '@/components/Tabbar/index.vue';
import store from '@/store';

const routes = [
  // 嵌入到商城小程序中的医生认证页面
  {
    path: '/doctorApply',
    name: 'doctorApply',
    component: () => import('@/views/doctorApply.vue'),
  },
  // 公众号内部的的医生认证页面
  {
    path: '/doctorApply2/:rzState',
    name: 'doctorApply2',
    props: true,
    component: () => import('@/views/doctorApply2'),
  },
  //
  {
    path: '/medicien',
    name: 'medicien',
    meta: {
      title: '配药代办',
      keepAlive: true,
      isDoctor: 0,
    },
    component: () => import('@/views/medicien'),
  },
  // 协议/条款/常见问题共享页
  {
    path: '/document/:type',
    name: 'document',
    props: true,
    component: () => import('@/views/document'),
  },
  // 我的推广
  {
    path: '/extension',
    name: 'extension',
    meta: {
      title: '我的推广',
      keepAlive: true,
    },
    component: () => import('@/views/extension'),
  },
  // 主页
  {
    path: '/',
    name: 'index',
    redirect() {
      if (store.getters['user/userInfo'].isDoctor) {
        return 'health';
      }
      return 'home';
    },
  },

  {
    path: '/home',
    name: 'home',
    meta: {
      title: '比心健康问诊',
      keepAlive: true,
      isDoctor: 0,
    },
    components: {
      default: () => import('@/views/home/index.vue'),
      Tabbar,
    },
  },
  {
    path: '/health',
    name: 'health',
    meta: {
      title: '健康服务',
      isDoctor: 1,
    },
    components: {
      default: () => import('@/views/health/index.vue'),
      Tabbar,
    },
  },
  {
    path: '/platform',
    name: 'platform',
    meta: {
      title: '使用教程',
      keepAlive: true,
      isDoctor: 1,
    },
    components: {
      default: () => import('@/views/platform/index.vue'),
      Tabbar,
    },
  },
  {
    path: '/ask',
    name: 'ask',
    meta: {
      title: '比心健康问诊',
      keepAlive: true,
      isDoctor: 0,
    },
    components: {
      default: () => import('@/views/ask/index.vue'),
      Tabbar,
    },
  },
  {
    path: '/my',
    name: 'my',
    meta: {
      title: '比心健康问诊',
      keepAlive: true,
    },
    components: {
      default: () => import('@/views/my/index.vue'),
      Tabbar,
    },
  },
  // 登录
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '比心健康',
    },
    component: () => import('@/views/login'),
  },
  // 个人中心
  {
    path: '/userInfo',
    name: 'userInfo',
    meta: {
      title: '个人中心',
    },
    component: () => import('@/views/userInfo'),
  },
  {
    path: '/updatePhone',
    name: 'updatePhone',
    redirect: { name: 'stepone' },
    component: () => import('@/views/updatePhone'),
    children: [
      {
        path: 'stepone',
        name: 'stepone',
        meta: {
          title: '修改手机号',
        },
        component: () => import('@/views/updatePhone/stepone'),
      },
      {
        path: 'steptwo',
        name: 'steptwo',
        meta: {
          title: '验证手机号',
        },
        component: () => import('@/views/updatePhone/steptwo'),
      },
    ],
  },
  // 绑定手机
  {
    path: '/bindPhone/:to',
    name: 'bindPhone',
    props: true,
    meta: {
      title: '比心健康',
    },
    component: () => import('@/views/bindPhone'),
  },
  // 问诊设置
  {
    path: '/setting',
    name: 'setting',
    meta: {
      title: '问诊设置',
      keepAlive: true,
      isDoctor: 1,
    },
    component: () => import('@/views/setting/index.vue'),
    children: [
      {
        path: 'imageText',
        name: 'imageText',
        meta: {
          title: '图文问诊设置',
          isDoctor: 1,
        },
        component: () => import('@/views/setting/imageText'),
      },
      {
        path: 'prescription',
        name: 'prescription',
        meta: {
          title: '续方配药设置',
          isDoctor: 1,
        },
        component: () => import('@/views/setting/prescription'),
      },
      {
        path: 'phone',
        name: 'phone',
        meta: {
          title: '电话问诊设置',
          isDoctor: 1,
        },
        component: () => import('@/views/setting/phone'),

      },
      {
        path: 'phoneSchedue',
        name: 'phoneSchedue',
        meta: {
          title: '排班时间设置',
          prevTitle: '电话问诊设置',
          isDoctor: 1,
        },
        component: () => import('@/views/setting/phoneSchedue'),
      },
      {
        path: 'phoneStop',
        name: 'phoneStop',
        meta: {
          title: '临时停诊设置',
          prevTitle: '电话问诊设置',
          isDoctor: 1,
        },
        component: () => import('@/views/setting/phoneStop'),
      },

      {
        path: 'plus',
        name: 'plus',
        meta: {
          title: '门诊加号设置',
          isDoctor: 1,
        },
        component: () => import('@/views/setting/plus'),
      },
      {
        path: 'plusSchedule',
        name: 'plusSchedule',
        meta: {
          title: '门诊加号设置',
          isDoctor: 1,
        },
        component: () => import('@/views/setting/plusSchedule'),
      },
      {
        path: 'plusStop',
        name: 'plusStop',
        meta: {
          title: '门诊加号设置',
          isDoctor: 1,
        },
        component: () => import('@/views/setting/plusStop'),
      },

    ],
  },
  // 我的患者/医生
  {
    path: '/follow',
    name: 'follow',
    meta: {
      keepAlive: true,
    },
    component: () => import('@/views/follow'),
  },
  // 我的患者详情
  {
    path: '/followPatientDetail/:id',
    name: 'followPatientDetail',
    meta: {
      title: '患者信息',
      isDoctor: 1,
    },
    props: true,
    component: () => import('@/views/followPatientDetail'),
  },
  // 我的关注
  {
    path: '/patientMyFollow',
    name: 'patientMyFollow',
    meta: {
      title: '我的关注',
      keepAlive: true,
    },
    component: () => import('@/views/patientMyFollow'),
  },
  // 钱包
  {
    path: '/wallet',
    name: 'wallet',
    meta: {
      title: '我的钱包',
      isDoctor: 1,
    },
    component: () => import('@/views/wallet'),
  },
  // 提现
  {
    path: '/walletCash/:balance',
    name: 'walletCash',
    props: true,
    meta: {
      title: '提现申请',
      isDoctor: 1,
    },
    component: () => import('@/views/walletCash'),
  },
  // 钱包详情
  {
    path: '/walletDetail/:id',
    name: 'walletDetail',
    meta: {
      title: '收支详情',
      isDoctor: 1,
    },
    component: () => import('@/views/walletDetail'),
  },
  // 二维码
  {
    path: '/qrCode',
    name: 'qrCode',
    meta: {
      title: '扫码关注',
      keepAlive: true,
    },
    component: () => import('@/views/qrCode'),
  },
  // 图文问诊 医生界面
  {
    path: '/doctorImageText/:id',
    props: true,
    name: 'doctorImageText',
    meta: {
      title: '问诊',
      isDoctor: 1,
    },
    component: () => import('@/views/doctorImageText'),
  },
  // 医生图文问诊订单
  {
    path: '/doctorOrder/:status',
    props: true,
    name: 'doctorOrder',
    meta: {
      title: '问诊',
      keepAlive: true,
      isDoctor: 1,
    },
    component: () => import('@/views/doctorOrder'),
  },
  // 医生查看快速问诊订单
  {
    path: '/doctorFastOrder',
    name: 'doctorFastOrder',
    meta: {
      title: '抢单专区',
      keepAlive: true,
      isDoctor: 1,
    },
    component: () => import('@/views/doctorFastOrder'),
  },

  // 医生加号问诊订单
  {
    path: '/doctorPlusOrder',
    name: 'doctorPlusOrder',
    meta: {
      title: '门诊加号',
      isDoctor: 1,
    },
    component: () => import('@/views/doctorPlusOrder'),
  },
  // 患者加号问诊订单详情
  {
    path: '/doctorPlusOrderDetail/:id',
    name: 'doctorPlusOrderDetail',
    props: true,
    meta: {
      title: '门诊加号',
      isDoctor: 1,
    },
    component: () => import('@/views/doctorPlusOrderDetail'),
  },
  // 图文问诊 患者界面
  {
    path: '/patientImageText/:id',
    props: true,
    name: 'patientImageText',
    meta: {
      title: '问诊',
      // keepAlive: true,
      isDoctor: 0,
    },
    component: () => import('@/views/patientImageText'),
  },
  // 患者图文问诊订单
  {
    path: '/patientOrder/:status',
    props: true,
    name: 'patientOrder',
    meta: {
      title: '问诊',
      keepAlive: true,
      isDoctor: 0,
    },
    component: () => import('@/views/patientOrder'),
  },
  // 患者加号问诊订单
  {
    path: '/patientPlusOrder',
    name: 'patientPlusOrder',
    meta: {
      title: '门诊加号',
      isDoctor: 0,
    },
    component: () => import('@/views/patientPlusOrder'),
  },

  // 快速回复模板
  {
    path: '/templateList',
    name: 'templateList',
    meta: {
      title: '快速回复',
      keepAlive: true,
      isDoctor: 1,
    },
    components: {
      default: () => import('@/views/templateList'),
      FastButton: FastBottomButton,
    },
  },
  // 关注病例
  {
    path: '/caseList',
    name: 'caseList',
    meta: {
      title: '关注病例',
      keepAlive: true,
      isDoctor: 1,
    },
    components: {
      default: () => import('@/views/caseList'),
      FastButton: FastBottomButton,
    },
  },
  // 招募列表
  {
    path: '/recruitList',
    name: 'recruitList',
    meta: {
      title: '临床招募',
      keepAlive: true,
    },
    components: {
      default: () => import('@/views/recruitList'),
      FastButton: FastBottomButton,
    },
  },
  {
    path: '/recruitDetail/:id',
    name: 'recruitDetail',
    props: true,
    meta: {
      title: '临床招募',
    },
    components: {
      default: () => import('@/views/recruitDetail'),
      FastButton: FastBottomButton,
    },
  },
  {
    path: '/recruitForm/:doctorId/:doctorname/:recruitId',
    name: 'recruitForm',
    props: true,
    meta: {
      title: '临床招募',
    },
    components: {
      default: () => import('@/views/recruitForm'),
      FastButton: FastBottomButton,
    },
  },
  {
    path: '/myRecruit',
    name: 'myRecruit',
    meta: {
      title: '招募申请',
    },
    components: {
      default: () => import('@/views/myRecruit'),
      FastButton: FastBottomButton,
    },
  },
  {
    path: '/myRecruitDetail/:id',
    name: 'myRecruitDetail',
    meta: {
      title: '申请详情',
    },
    props: true,
    components: {
      default: () => import('@/views/myRecruitDetail'),
      FastButton: FastBottomButton,
    },
  },
  {
    path: '/myRecruitDoctor/:id',
    name: 'myRecruitDoctor',
    meta: {
      title: '申请详情',
    },
    props: true,
    components: {
      default: () => import('@/views/myRecruitDoctor'),
      FastButton: FastBottomButton,
    },
  },

  // 心意
  {
    path: '/mindList',
    name: 'mindList',
    meta: {
      keepAlive: true,
    },
    components: {
      default: () => import('@/views/mindList'),
      FastButton: FastBottomButton,
    },
  },
  {
    path: '/commentList',
    name: 'commentList',
    props: true,
    meta: {
      title: '评价',
      keepAlive: true,
    },
    components: {
      default: () => import('@/views/commentList'),
      FastButton: FastBottomButton,
    },
  },
  {
    path: '/commentRepresentationsList',
    name: 'commentRepresentationsList',
    props: true,
    meta: {
      title: '评价申诉',
      keepAlive: true,
    },
    components: {
      default: () => import('@/views/commentRepresentationsList'),
      FastButton: FastBottomButton,
    },
  },

  {
    path: '/commentAdd/:orderId',
    name: 'commentAdd',
    props: true,
    meta: {
      title: '发布评价',
    },
    components: {
      default: () => import('@/views/commentAdd'),
      FastButton: FastBottomButton,
    },
  },
  {
    path: '/commentDetail/:orderId',
    name: 'commentDetail',
    props: true,
    meta: {
      title: '查看评价',
    },
    components: {
      default: () => import('@/views/commentDetail'),
      FastButton: FastBottomButton,
    },
  },

  // 搜索页
  {
    path: '/search',
    name: 'search',
    meta: {
      title: '点名咨询',
      keepAlive: true,
    },
    components: {
      default: () => import('@/views/search'),
      FastButton: FastBottomButton,
    },
  },
  // 医生主页
  {
    path: '/doctorHomepage/:id',
    name: 'doctorHomepage',
    props: true,
    meta: {

    },
    components: {
      default: () => import('@/views/doctorHomepage'),
      FastButton: FastBottomButton,
    },
  },
  {
    path: '/hospitalHomepage/:id',
    name: 'hospitalHomepage',
    props: true,
    meta: {
      title: '医院主页',
    },
    components: {
      default: () => import('@/views/hospitalHomepage'),
      FastButton: FastBottomButton,
    },
  },

  // 医生的患者评价
  {
    path: '/doctorComment/:id',
    name: 'doctorComment',
    props: true,
    components: {
      default: () => import('@/views/doctorComment'),
      FastButton: FastBottomButton,
    },
  },

  // 医生义诊列表
  {
    path: '/doctorFreeClinic',
    name: 'doctorFreeClinic',
    meta: {
      title: '义诊',
    },
    components: {
      default: () => import('@/views/doctorFreeClinic'),
      FastButton: FastBottomButton,
    },
  },
  // 提问综合表单
  {
    path: '/askForm/:name/:doctorId/:qorderEnum/:price/:oldprice',
    name: 'askForm',
    props: true,
    meta: {
      title: '问诊',
    },
    components: {
      default: () => import('@/views/askForm'),
    },
  },
  // 快速咨询表单
  {
    path: '/quickForm',
    name: 'quickForm',
    meta: {
      title: '快速咨询',
    },
    components: {
      default: () => import('@/views/quickForm'),
    },
  },
  // 续方配药表单
  {
    path: '/prescriptionForm',
    name: 'prescriptionForm',
    meta: {
      title: '续方配药',
    },
    components: {
      default: () => import('@/views/prescriptionForm'),
    },
  },
  // 续方配药详情
  {
    path: '/prescriptionDetail',
    name: 'prescriptionDetail',
    meta: {
      title: '续方配药',
    },
    components: {
      default: () => import('@/views/prescriptionDetail'),
    },
  },
  // 门诊加号表单
  {
    path: '/plusForm',
    name: 'plusForm',
    meta: {
      title: '门诊加号',
    },
    components: {
      default: () => import('@/views/plusForm'),
    },
  },
  // 系统消息
  {
    path: '/messageList',
    name: 'messageList',
    meta: {
      title: '消息中心',
      keepAlive: true,
    },
    components: {
      default: () => import('@/views/messageList'),
      FastButton: FastBottomButton,
    },
  },
  {
    path: '/messageDetail/:id',
    name: 'messageDetail',
    props: true,
    meta: {
      title: '消息中心',
    },
    components: {
      default: () => import('@/views/messageDetail'),
      FastButton: FastBottomButton,
    },
  },
  // 招聘信息
  {
    path: '/jobList',
    name: 'jobList',
    meta: {
      title: '招聘信息',
      keepAlive: true,
    },
    components: {
      default: () => import('@/views/jobList'),
      FastButton: FastBottomButton,
    },
  },
  // 招聘信息
  {
    path: '/jobDetail/:id',
    name: 'jobDetail',
    props: true,
    meta: {
      title: '招聘信息',
    },
    components: {
      default: () => import('@/views/jobDetail'),
      FastButton: FastBottomButton,
    },
  },

  // 专家风采
  {
    path: '/expertList',
    name: 'expertList',
    meta: {
      title: '专家风采',
      keepAlive: true,
    },
    components: {
      default: () => import('@/views/expertList'),
      FastButton: FastBottomButton,
    },
  },
  // 专家风采
  {
    path: '/expertDetail/:id',
    name: 'expertDetail',
    props: true,
    components: {
      default: () => import('@/views/expertDetail'),
      FastButton: FastBottomButton,
    },
  },


  // 健康科普
  {
    path: '/scienceList',
    name: 'scienceList',
    meta: {
      title: '健康科普',
      keepAlive: true,
    },
    components: {
      default: () => import('@/views/scienceList'),
      FastButton: FastBottomButton,
    },
  },
  // 科普详情
  {
    path: '/scienceDetail/:id',
    name: 'scienceDetail',
    props: true,
    meta: {
      title: '健康科普',
    },
    components: {
      default: () => import('@/views/scienceDetail'),
      FastButton: FastBottomButton,
    },
  },
  // 健康档案列表
  {
    path: '/archivesList',
    name: 'archivesList',
    meta: {
      title: '健康档案',
      keepAlive: true,
    },
    components: {
      default: () => import('@/views/archivesList'),
    },
  },
  // 健康档案
  {
    path: '/archivesForm/:id',
    name: 'archivesForm',
    meta: {
      title: '健康档案',
    },
    props: true,
    components: {
      default: () => import('@/views/archivesForm'),
    },
  },
  // 支付确认页面
  {
    path: '/payConfirm/:id/:payData',
    name: 'payConfirm',
    props: true,
    components: {
      default: () => import('@/views/payConfirm'),
    },
  },
  // 支付状态页
  {
    path: '/payStatus',
    name: 'payStatus',
    components: {
      default: () => import('@/views/payStatus'),
    },
  },

];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  mode: 'history',
  scrollBehavior: () => ({
    x: 0, y: 0, behavior: 'smooth',
  }),
});

export default router;
