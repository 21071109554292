/**
 * @description 存储搜索历史记录
 */
import { getStorage, setStorage } from '@/utils/storage.js';

const state = () => ({
  searchHistory: getStorage('searchHistory') ? getStorage('searchHistory') : [],
});
const getters = {
  searchHistory: (state) => state.searchHistory,
};
const mutations = {
  setSearchHistory(state, search) {
    if (search == false) {
      state.searchHistory = [];
      setStorage([], 'searchHistory');
    } else {
      const arr = getStorage('searchHistory') ? getStorage('searchHistory').split(',') : [];

      if (arr.indexOf(search) == -1) {
        if (arr.length >= 10) {
          arr.splice(arr.length - 1, 1);
        }
        arr.unshift(search);
      }

      state.searchHistory = arr;
      setStorage(arr, 'searchHistory');
    }
  },
};
const actions = {
  resetAllSearch({ dispatch }) {
    dispatch('setSearchHistory', false);
  },
  /**
   * @description 设置
   * @param {*} { commit }
   * @param {*} token
   */
  setSearchHistory({ commit }, search) {
    commit('setSearchHistory ', search);
  },
};
export default { state, getters, mutations, actions };
