<template>
  <router-view v-slot="{ Component }">
    <transition name="fade">
      <keep-alive :include="includeList">
        <component :is="Component" />
      </keep-alive>
    </transition>
  </router-view>
  <router-view name="FastButton" />
  <router-view name="Tabbar" />
</template>

<script>
import { ref } from '@vue/reactivity';
import { watch } from '@vue/runtime-core';
import { useRoute } from 'vue-router';

export default {
  name: 'App',
  setup() {
    // 需要缓存的组件name值
    const includeList = ref([]);
    const route = useRoute();
    watch(route, (to) => {
      if (to.meta.keepAlive && includeList.value.indexOf(to.name) === -1) {
        includeList.value.push(to.name);
      }
    });
    return {
      includeList,
    };
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isReload: true,
    };
  },
  methods: {
    reload() {
      this.isReload = false;
      this.$nextTick(() => {
        this.isReload = true;
      });
    },
  },
};
</script>
<style lang="scss">
@import './style/mixins.scss';
@import './style/common.scss';

body {
  background-color: #f6f6f6;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #f7f9fb;
  height: 100vh;
  max-width: 575px;
  position: relative;
  margin: 0 auto;
}

.container {
  background-color: white;
  // padding-bottom: 80px;
  padding-bottom: constant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
  padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS>11.2*/
}

p {
  margin: 0;
}
.padding15 {
  padding-left: 15px;
  padding-right: 15px;
}

.box-upload {
  padding: 10px 16px;
  .box-upload-container {
    margin-right: 10px;
    display: inline-block;

    .phpto-box {
      width: 90px;
      height: 90px;
      border-radius: 10px;
      border: 1px dashed #a6aeb3;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      .img {
        width: 50px;
        height: 35px;
      }
    }
    .phpto-box:nth-of-type(3n) {
      margin-right: 0;
    }
  }
}

.linear-blue {
  background: linear-gradient(135deg, #90aafe, #5e7dff);
  box-shadow: 0px 3px 10px 0px rgba(95, 126, 255, 0.5);
}

.top-bg {
  @include bg-image('./assets/bg');
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 187px;
  // border-radius: 50%;
  z-index: 0;
}

.grid-title {
  font-size: $font13;
  color: $black;
  margin-top: 10px;
  font-weight: 500;
}

.white {
  color: white;
}
:root {
  --van-blue: #5e7dff;
  --van-font-size-md: 15px !important;
  --van-button-default-font-size: 17px !important;
  --van-font-size-lg: 15px !important;
  // --van-cell-vertical-padding: 14px !important;
  --van-tabs-line-height: 50px !important;
  /* --van-line-height-md: 50px !important; */
  --van-tabs-bottom-bar-width: 5px !important;
  --van-tabs-bottom-bar-height: 5px !important;
  --van-cell-line-height: 24px !important;
  --van-padding-md: 15px !important;
  --van-button-default-height: 40px !important;
  --van-divider-margin: 0 !important;

  --van-search-padding: 15px !important;
  --van-cell-font-size: 14px !important;
  --van-password-input-background-color: #f2f3f5 !important;
  .van-cell__right-icon {
    font-size: 12px !important;
  }

  --van-grid-item-content-background-color: none !important;
  --van-grid-item-content-padding: var(--van-padding-md) var(--van-padding-xs) 0 !important;
  --van-grid-item-text-color: $black !important;

  --van-dropdown-menu-box-shadow: none !important;
  --van-dropdown-menu-height: 48px !important;
  --van-dropdown-menu-title-font-size: 15px !important;
  --van-tree-select-nav-item-padding: 10px !important;

  --van-empty-description-color: #696e72 !important;
  --van-empty-padding: 100px 0 !important;
  .van-tabs__line {
    bottom: 23px;
    z-index: 0;
  }
  .van-picker-column__item--selected {
    color: #5e7dff;
  }
}
</style>
