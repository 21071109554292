/**
 * @description 登录、获取用户信息、退出登录、清除token逻辑，不建议修改
 */
import { getStorage, setStorage } from '@/utils/storage.js';

const state = () => ({
  template: getStorage('template') ? getStorage('template') : '',
});
const getters = {
  template: (state) => state.template,
};
const mutations = {

  setTemplate(state, template) {
    state.template = template;
    setStorage(template, 'template');
  },
};
const actions = {

  /**
   * @description 设置
   * @param {*} { commit }
   * @param {*} token
   */
  setTemplate({ commit }, template) {
    commit('setTemplate ', template);
  },
};
export default { state, getters, mutations, actions };
