/**
 * @description 登录、获取用户信息、退出登录、清除token逻辑，不建议修改
 */
import { getToken, removeToken, setToken } from '@/utils/token';
import { getStorage, setStorage } from '@/utils/storage.js';

const state = () => ({
  token: getToken(),
  userInfo: getStorage('userInfo') ? JSON.parse(getStorage('userInfo')) : {},
});
const getters = {
  token: (state) => state.token,
  userInfo: (state) => state.userInfo,
};
const mutations = {
  /**
   * @description 设置token
   * @param {*} state
   * @param {*} token
   */
  setToken(state, token) {
    state.token = token;
    setToken(token);
  },
  setUserInfo(state, userInfo) {
    state.userInfo = userInfo;
    setStorage(JSON.stringify(userInfo), 'userInfo');
  },
};
const actions = {
  async resetAll({ commit, dispatch }) {
    console.log(commit);
    await dispatch('setToken', '');
    removeToken();
  },

  /**
   * @description 设置token
   * @param {*} { commit }
   * @param {*} token
   */
  setToken({ commit }, token) {
    commit('setToken', token);
  },
  /**
   * @description 设置
   * @param {*} { commit }
   * @param {*} token
   */
  setUserInfo({ commit }, userInfo) {
    commit('setUserInfo', userInfo);
  },
};
export default { state, getters, mutations, actions };
