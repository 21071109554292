/**
 * @description 导出通用配置
 */
module.exports = {
  // 标题
  title: '比心健康',
  // 不经过token校验的路由，白名单路由建议配置到与login页面同级，如果需要放行带传参的页面，请使用query传参，配置时只配置path即可
  routesWhiteList: ['/login', '/register', '/404', '/403'],
  bindphoneRoutesList: ['quickForm', 'askForm', 'plusForm']
};
