/**
 * @description 子配置，网络配置导出
 */
const network = require('./net.config');
const setting = require('./setting.config');

module.exports = {
  ...network,
  ...setting,
};
